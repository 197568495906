export default {
  data: () => ({}),
  computed: {},
  methods: {
    switchTheme() {
      window.location.reload();
      if (!window.localStorage.getItem("idrive-theme") || window.localStorage.getItem("idrive-theme") === 'default') {
        window.localStorage.setItem("idrive-theme", 'dark')
      } else if (window.localStorage.getItem("idrive-theme") === 'dark') {
        window.localStorage.setItem("idrive-theme", 'default')
      }
    }
  }
}

